import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import AccountActivationIcon from 'components/icons/account/AccountActivationIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';

const ActivationCard = () => {
  const { activationPagePath } = usePagePathWithLocale();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  return (
    <div className="bg-base-accent-3 px-20 py-24 lg:px-32">
      <div className="flex justify-between gap-20">
        <div className="flex flex-col gap-8">
          <Typography className="text-18 font-bold leading-[22.5px] text-neutral-4">
            {formatAccountMessage({
              id: 'activation.headline',
              defaultMessage: 'Do you already own a Selgros card?',
            })}
          </Typography>
          <div className="text-16 leading-[24px] text-neutral-4">
            <Link link={activationPagePath} className="font-bold text-base-accent-1">
              {formatAccountMessage({
                id: 'activation.link',
                defaultMessage: 'In this case, please use our Activation Form',
              })}
            </Link>
          </div>
        </div>
        <div className="self-center">
          <span className="flex h-40 w-40 items-center justify-center rounded-full border border-[#D8EEFD] bg-neutral-5 font-bold text-base-accent-1">
            <AccountActivationIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActivationCard;
