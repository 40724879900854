import React, { useState } from 'react';
import useTrack from 'components/commercetools-ui/organisms/authentication/useTrack';
import {
  mapFormDataToAccountRegisterBodyB2B,
  mapFormDataToAccountRegisterBodyB2C,
} from 'helpers/utils/mapFormDataToAccountRegisterBody';
import { useAccount } from 'frontastic';
import B2BStep1 from './b2b/step1';
import B2BStep2 from './b2b/step2';
import B2BStep3 from './b2b/step3';
import B2BStep4 from './b2b/step4';
import B2CStep1 from './b2c/step1';
import B2CStep2 from './b2c/step2';
import B2CStep3 from './b2c/step3';
import Step from './step';
import { DataB2B, DataB2C, Form, RegistrationContent } from './types';

interface Props {
  form: Form;
  studio: RegistrationContent;
}

const Steps = ({ form, studio }: Props) => {
  // Handle navigating steps
  const [current, setCurrent] = useState(0);
  const goToNextStep = () => setCurrent(current + 1);
  const onEdit = (index: number) => setCurrent(index);

  // Handle switching customer type: true = B2B, false = B2C (default)
  const [b2b, setB2B] = useState(false);
  const b2bState = { value: b2b, set: setB2B };

  // Backend registration callback
  const { register } = useAccount();
  const { trackUserRegistration } = useTrack();

  const onSubmit = async () => {
    try {
      const accountRegisterBody = b2b
        ? mapFormDataToAccountRegisterBodyB2B(form.dataState.value as DataB2B)
        : mapFormDataToAccountRegisterBodyB2C(form.dataState.value as DataB2C);
      const response = await register(accountRegisterBody);

      if (!response.accountId) {
        // Account creation error, display a message in the UI
        form.submitError.set(true);
      } else {
        const trackEmails = [form.dataState.value.email];

        if (b2b) {
          const { contactPersons } = form.dataState.value as DataB2B;

          contactPersons?.map((person) => trackEmails.push(person.email));
        }

        trackUserRegistration(trackEmails);
        form.completeState.set(true);
      }
    } catch (err) {
      // API request error, display a message in the UI
      form.submitError.set(true);
    }
  };

  // Custom object to handle all Step props
  const stepProps = {
    b2b: {
      step1: {
        b2bState,
        form,
        studio: studio.b2bStep1[0],
        onClickSubmit: goToNextStep,
      },
      //if studio.b2bStep2 doesn't exist
      step2:
        typeof studio.b2bStep2[0] !== 'object' || Object.keys(studio.b2bStep2[0]).length > 0
          ? {
              form,
              studio: studio.b2bStep2[0],
              onClickSubmit: goToNextStep,
            }
          : undefined,
      step3: {
        form,
        studio: studio.b2bStep3[0],
        onClickSubmit: studio.b2bStep4 ? goToNextStep : onSubmit,
      },
      step4: studio.b2bStep4
        ? {
            form,
            studio: studio.b2bStep4[0],
            onClickSubmit: onSubmit,
          }
        : undefined,
    },
    b2c: {
      step1: {
        b2bState,
        form,
        studio: studio.b2cStep1[0],
        onClickSubmit: goToNextStep,
      },
      //if studio.b2cStep2 dont exist, dont render step2
      step2:
        typeof studio.b2cStep2[0] !== 'object' || Object.keys(studio.b2cStep2[0]).length > 0
          ? {
              form,
              studio: studio.b2cStep2[0],
              onClickSubmit: goToNextStep,
            }
          : undefined,

      step3: {
        form,
        studio: studio.b2cStep3[0],
        onClickSubmit: onSubmit,
      },
    },
  };

  // Configuration of the steps for each customer type
  const steps = () => {
    if (b2b) {
      return [
        {
          label: studio.b2bStep1[0].b2bStep1HeaderTitle,
          Component: <B2BStep1 {...stepProps.b2b.step1} />,
        },
        ...(stepProps.b2b.step2
          ? [
              {
                label: studio.b2bStep2[0].b2bStep2HeaderTitle,
                Component: <B2BStep2 {...stepProps.b2b.step2} />,
              },
            ]
          : []),
        {
          label: studio.b2bStep3[0].b2bStep3HeaderTitle,
          Component: <B2BStep3 {...stepProps.b2b.step3} />,
        },
        ...(stepProps.b2b.step4
          ? [
              {
                label: 'Create Accounts for your Employees',
                Component: <B2BStep4 {...stepProps.b2b.step4} />,
              },
            ]
          : []),
      ];
    } else {
      return [
        {
          label: studio.b2cStep1[0].b2cStep1HeaderTitle,
          Component: <B2CStep1 {...stepProps.b2c.step1} />,
        },
        ...(stepProps.b2c.step2
          ? [
              {
                label: studio.b2cStep2[0].b2cStep2HeaderTitle,
                Component: <B2CStep2 {...stepProps.b2c.step2} />,
              },
            ]
          : []),
        {
          label: studio.b2cStep3[0].b2cStep3HeaderTitle,
          Component: <B2CStep3 {...stepProps.b2c.step3} />,
        },
      ];
    }
  };

  return (
    <div className="mx-20 lg:mx-46">
      {steps().map(({ Component, label }, index) => (
        <Step
          key={index}
          label={label}
          number={index + 1}
          isExpanded={index === current}
          isCompleted={index < current}
          onEdit={() => onEdit(index)}
          Component={Component}
        />
      ))}
    </div>
  );
};

export default Steps;
